import { Container, Row, Col, Tab} from "react-bootstrap";

export const Projects = () => {

  const projects = [
    {
      title: "RevAMP",
      description: "Full redesign of University of California, Davis's parking app into a more intuitive and user-friendly experience using python for backend and react for frontend.",
      languages: ["Python", "Next.js", "MongoDB", "Flask"],
      githubUrl: null,
      demoUrl: "https://rev-amp-front.vercel.app"
    },
    {
      title: "Discord Activity Tracker",
      description: "A Discord bot that tracks user activity and monitors server events, storing the data in a MongoDB database where users and admins can review user behavior data.",
      languages: ["Python", "MongoDB", "Discord.py"],
      githubUrl: "https://github.com/scaredvc/discord_aio_bot.git",
      demoUrl: null
    },
    {
      title: "Jumpshot Comparison",
      description: "Analyzes basketball jump shots using video footages to provide a similarity score compared to an NBA player, utilizing OpenCV for video processing and GPT-4o for mechanics analysis.",
      languages: ["Python", "Javascript", "OpenCV", "GPT-4o"],
      githubUrl: "https://github.com/scaredvc/jumpshot.git",
      demoUrl: null
    },
  ];

  return (
    <section className="project" id="projects">
      <Container>
        <Row>
          <Col size={12}>
            <h2>Projects</h2>
            <Tab.Container id="projects-tabs" defaultActiveKey="first">
              <Tab.Pane eventKey="first">
                <Row>
                  {projects.map((project, index) => (
                    <Col key={index} md={6} lg={4} className="mb-4">
                      <div className="project-box">
                        <div className="project-header">
                          <h4>{project.title}</h4>
                          <div className="language-tags">
                            {project.languages.map((lang, i) => (
                              <span key={i} className="language-tag">{lang}</span>
                            ))}
                          </div>
                        </div>
                        <p className="project-description">{project.description}</p>
                        <div className="project-links">
                          {project.githubUrl && (
                            <a href={project.githubUrl} target="_blank" rel="noopener noreferrer">
                              GitHub
                            </a>
                          )}
                          {project.demoUrl && (
                            <a href={project.demoUrl} target="_blank" rel="noopener noreferrer">
                              Demo
                            </a>
                          )}
                        </div>
                      </div>
                    </Col>
                  ))}
                </Row>
              </Tab.Pane>
            </Tab.Container>
          </Col>
        </Row>
      </Container>
    </section>
  )
}
